import { useMeta } from 'vue-meta'
import  UserService from '../../Services/UserDataService.js'

export default {
    name: "Dashboard",
    setup () {
        useMeta({ title: 'Dashboard' })
    },
    computed: {
        user() {
            return this.$store.getters['auth/user']
        },
    },
    data() {
        return {
            allData: null,
        };
     },
    methods: {
        getDashboardContractsData() {
            UserService.getDashboardData().then((res) => {
                this.allData = res.data;
            }).catch((err) => {
                console.log(err);
            })
        }
    },
    created() {
        this.getDashboardContractsData();
    }
}