export const PERMISSIONS = {
  CONTRACT_EDIT: "Contract Edit",
};
export const BILL_PERMISSIONS = {
  BILL_EDIT: "Bill Edit",
  BILL_APPROVE: "Bill Approve",
};

export const BILL_STATUS = {
  IN_PROGRESS: {
    id: 1,
    value: "ARCHIVED",
  },
};
export const CONTRACT_STATUS = {
  IN_PROGRESS: {
    id: 1,
    value: "ARCHIVED",
  },
};
