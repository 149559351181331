<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0">Dashboard</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item active"></li>
                </ol>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                    <div class="inner">
<!--                        <h3>15</h3>-->
                        <h3 v-if="allData">{{allData.allContracts }}</h3>
                        <h3 v-else>0</h3>
                        <p>All contracts</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-file-contract"></i>
                    </div>
                    <router-link :to="{ name: 'contracts' }" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                    <div class="inner">
<!--                        <h3>53<sup style="font-size: 20px">%</sup></h3>-->
                        <h3 v-if="allData">{{allData.activeContracts}}</h3>
                        <h3 v-else>0</h3>
                        <p>Active Contracts</p>
                    </div>
                    <div class="icon">
                        <i class="far fa-file-alt"></i>
                    </div>
                    <router-link :to="{ name: 'contracts' , query: { status: 5 } }" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="small-box bg-warning">
                    <div class="inner">
<!--                        <h3>44</h3>-->
                        <h3 v-if="allData">{{allData.expiringContracts}}</h3>
                        <h3 v-else>0</h3>
                        <p>Expiring contracts</p>
                    </div>
                    <div class="icon">
                        <i class="far fa-copy"></i>
                    </div>
                    <router-link :to="{ name: 'contracts',  query: { status: 6 } }" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                    <div class="inner">
<!--                        <h3>65</h3>-->
                        <h3 v-if="allData">{{allData.expiredContracts}}</h3>
                        <h3 v-else>0</h3>
                        <p>Expired contracts</p>
                    </div>
                    <div class="icon">
                        <i class="far fa-copy"></i>
                    </div>
                    <router-link :to="{ name: 'contracts',  query: { status: 7 } }" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                </div>
            </div>
        </div>

        <div class="row1">
<!--            <h1>Welcome,</h1>-->
<!--            <h3>{{ user.first_name }} {{ user.last_name }}</h3>-->
        </div>
    </div>
</section>