import { mapGetters, mapActions } from "vuex";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {
  SUPPLIER_LIST_GETTER,
  SUPPLIERS_PAGINATED_DATA_GETTER,
  SUPPLIER_GETTER,
  NEW_SUPPLIER_GETTER,
  FIRST_TIME_LOADED_GETTER,
  IS_LOADING_ALL_GETTER,
  IS_LOADING_GETTER,
  IS_CREATING_GETTER,
  CREATED_DATA_GETTER,
  IS_UPDATING_GETTER,
  UPDATED_DATA_GETTER,
  ERRORS_GETTER,
  FETCH_ALL_SUPPLIERS_ACTION,
  FETCH_DETAIL_SUPPLIER_ACTION,
  STORE_SUPPLIER_ACTION,
  UPDATE_SUPPLIER_ACTION,
  DELETE_SUPPLIER_ACTION,
  ADD_SUPPLIER_INPUT_ACTION,
  UPDATE_SUPPLIER_INPUT_ACTION,
  SET_ERRORS_ACTION,
} from "@/Store/Modules/Supplier/constants";
import { useMeta } from "vue-meta";

export default {
  name: "Suppliers",
  components: {
    VPagination,
  },
  setup() {
    useMeta({ title: "Suppliers" });
  },
  data() {
    return {
      modalAddActive: false,
      modalInfoActive: false,
      modalEditActive: false,
      query: {
        page: 1,
      },
      canShowAll: false,
      canShow: false,
      canAdd: false,
      canEdit: false,
      canDelete: false,
    };
  },
  computed: {
    ...mapGetters("suppliers", {
      supplier: SUPPLIER_GETTER,
      newSupplier: NEW_SUPPLIER_GETTER,
      supplierList: SUPPLIER_LIST_GETTER,
      suppliersPaginatedData: SUPPLIERS_PAGINATED_DATA_GETTER,
      createdData: CREATED_DATA_GETTER,
      updatedData: UPDATED_DATA_GETTER,
      isLoadingAll: IS_LOADING_ALL_GETTER,
      firstTimeLoaded: FIRST_TIME_LOADED_GETTER,
      isLoading: IS_LOADING_GETTER,
      isCreating: IS_CREATING_GETTER,
      isUpdating: IS_UPDATING_GETTER,
      errors: ERRORS_GETTER,
    }),
    isModalActive() {
      return this.$store.getters["ui/modalActive"];
    },
    rolePermissions() {
      return this.$store.getters["auth/rolePermissions"];
    },
    directPermissions() {
      return this.$store.getters["auth/directPermissions"];
    },
  },
  methods: {
    ...mapActions("suppliers", {
      fetchAllSuppliers: FETCH_ALL_SUPPLIERS_ACTION,
      fetchDetailSupplier: FETCH_DETAIL_SUPPLIER_ACTION,
      storeSupplier: STORE_SUPPLIER_ACTION,
      updateSupplier: UPDATE_SUPPLIER_ACTION,
      addSupplierInput: ADD_SUPPLIER_INPUT_ACTION,
      updateSupplierInput: UPDATE_SUPPLIER_INPUT_ACTION,
      deleteSupplier: DELETE_SUPPLIER_ACTION,
      setErrors: SET_ERRORS_ACTION,
    }),
    closeModal() {
      this.$store.dispatch("ui/setModalActive", false);
      this.modalAddActive = false;
      this.modalInfoActive = false;
      this.modalEditActive = false;
    },

    showAddModal() {
      this.$store.dispatch("ui/setModalActive", !this.isModalActive);
      this.setErrors(null);
      return (this.modalAddActive = !this.modalAddActive);
    },
    showInfoModal(id) {
      this.$store.dispatch("ui/setModalActive", !this.isModalActive);
      this.fetchDetailSupplier(id);
      return (this.modalInfoActive = !this.modalInfoActive);
    },
    showEditModal(id) {
      this.$store.dispatch("ui/setModalActive", !this.isModalActive);
      // this.$store.dispatch('suppliers/setErrors', null);
      this.fetchDetailSupplier(id);
      this.setErrors(null);
      return (this.modalEditActive = !this.modalEditActive);
    },
    addSupplierInputAction(e) {
      this.addSupplierInput(e);
    },
    updateSupplierInputAction(e) {
      this.updateSupplierInput(e);
    },
    onSubmitAdd() {
      const { name, bussines_no } = this.newSupplier;
      this.storeSupplier({
        name: name,
        bussines_no: bussines_no,
      });
    },
    onSubmitEdit() {
      const { id, name, nameNew, bussines_no, bussines_noNew } = this.supplier;
      let supplier = [];
      supplier["id"] = id;
      if (name !== nameNew) {
        supplier["name"] = nameNew;
      }
      if (bussines_no !== bussines_noNew) {
        supplier["bussines_no"] = bussines_noNew;
      }
      this.updateSupplier({ ...supplier });
    },

    showDeleteModal(id) {
      this.$swal
        .fire({
          text: "Are you sure you want to delete this supplier?",
          icon: "error",
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes",
          showCancelButton: true,
        })
        .then((result) => {
          if (result["isConfirmed"]) {
            this.deleteSupplier(id);
            // this.fetchAllTasks();
            this.$swal.fire({
              text: "Supplier has been deleted.",
              icon: "success",
              timer: 10000,
            });
          }
        });
    },
    getResults() {
      this.fetchAllSuppliers(this.query);
      console.log(this.fetchAllSuppliers);
    },
    getRouteQuery() {
      if (this.$route.query.page != null) {
        this.query.page = parseInt(this.$route.query.page);
      }
      return this.query;
    },
    modalContent() {},
    checkIfFieldHasErrors(errors, field) {
      if (errors != null && !this.isCreating) {
        if (errors[field] != null) {
          return true;
        }
      }
      return false;
    },
    checkIfUserHasPermissionToShowAll() {
      let permission = "Supplier Show All";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canShowAll = true);
      }
      if (p2.length >= 1) {
        return (this.canShowAll = true);
      }

      return (this.canShowAll = false);
    },
    checkIfUserHasPermissionToShow() {
      let permission = "Supplier Show";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canShow = true);
      }
      if (p2.length >= 1) {
        return (this.canShow = true);
      }

      return (this.canShow = false);
    },
    checkIfUserHasPermissionToAdd() {
      let permission = "Supplier Add";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canAdd = true);
      }
      if (p2.length >= 1) {
        return (this.canAdd = true);
      }

      return (this.canAdd = false);
    },
    checkIfUserHasPermissionToEdit() {
      let permission = "Supplier Edit";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canEdit = true);
      }
      if (p2.length >= 1) {
        return (this.canEdit = true);
      }

      return (this.canEdit = false);
    },
    checkIfUserHasPermissionToDelete() {
      let permission = "Supplier Delete";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canDelete = true);
      }
      if (p2.length >= 1) {
        return (this.canDelete = true);
      }

      return (this.canDelete = false);
    },
  },
  watch: {
    updatedData: function () {
      if (this.updatedData !== null && !this.isUpdating) {
        this.$store.dispatch("ui/setModalActive", false);
        this.$swal.fire({
          text: "Supplier has been updated successfully!",
          icon: "success",
          timer: 10000,
        });

        return (this.modalEditActive = !this.modalEditActive);
      }
    },
    createdData: function () {
      if (this.createdData !== null && !this.isCreating) {
        this.$store.dispatch("ui/setModalActive", false);
        this.$swal.fire({
          text: "Supplier has been added successfully!",
          icon: "success",
          timer: 10000,
        });

        return (this.modalAddActive = false);
      }
    },
    // errors: function () {
    //     console.log(this.errors.message)
    //     this.$toast.error("Error")
    // }
  },
  created() {
    this.checkIfUserHasPermissionToShowAll();
    this.checkIfUserHasPermissionToShow();
    this.checkIfUserHasPermissionToAdd();
    this.checkIfUserHasPermissionToEdit();
    this.checkIfUserHasPermissionToDelete();
    if (this.canShowAll) {
      this.fetchAllSuppliers(this.getRouteQuery());
    }
  },
};
