import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import Store from "../Store/index";
import Main from "../Modules/Main/Main.vue";

import Dashboard from "../Pages/Dashboard/Dashboard.vue";
import Blank from "../Pages/Blank/Blank.vue";
import Departments from "../Pages/Departments/Departments.vue";
import Companies from "../Pages/Companies/Companies.vue";
import Contracts from "../Pages/Contracts/Contracts.vue";

//added new
import Bills from "../Pages/Bills/Bills.vue";
import Suppliers from "../Pages/Suppliers/Suppliers.vue";

import ContractsArchive from "../Pages/ContractsArchive/ContractsArchive.vue";
import ContractTypes from "../Pages/ContractTypes/ContractTypes.vue";
import ResponsiblePerson from "../Pages/ResponsiblePerson/ResponsiblePerson.vue";
import ProcurementOfficer from "../Pages/ProcurementOfficer/ProcurementOfficer.vue";
import DirectorDepartment from "../Pages/DirectorDepartment/DirectorDepartment.vue";
import ExecutiveDirector from "../Pages/ExecutiveDirector/ExecutiveDirector.vue";
import LegalOffice from "../Pages/LegalOffice/LegalOffice.vue";
import Users from "../Pages/Users/Users.vue";
import Roles from "../Pages/Roles/Roles.vue";
import Permissions from "../Pages/Permissions/Permissions.vue";

import Home from "../views/Home.vue";
import Profile from "../Pages/Profile/Profile.vue";
import Login from "../Modules/Login/Login.vue";
import Register from "../Modules/Register/Register.vue";
import NotFound from "../Modules/NotFound/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/login",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/admin",
    name: "Main",
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/blank",
        name: "Blank",
        component: Blank,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/departments",
        name: "departments",
        component: Departments,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/companies",
        name: "companies",
        component: Companies,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/suppliers",
        name: "suppliers",
        component: Suppliers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/contracts",
        name: "contracts",
        component: Contracts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/contracts/archive",
        name: "contracts_archive",
        component: ContractsArchive,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/contract_types",
        name: "contract_types",
        component: ContractTypes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/users/responsible_persons",
        name: "responsible_persons",
        component: ResponsiblePerson,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/users/procurement_officer",
        name: "procurement_officer",
        component: ProcurementOfficer,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/users/director_departments",
        name: "director_departments",
        component: DirectorDepartment,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/users/executive_director",
        name: "executive_director",
        component: ExecutiveDirector,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/users/legal_office",
        name: "legal_office",
        component: LegalOffice,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/users",
        name: "users",
        component: Users,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/roles",
        name: "roles",
        component: Roles,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/permissions",
        name: "permissions",
        component: Permissions,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      // added
      {
        path: "/admin/bill",
        name: "Fatura",
        component: Bills,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/NotFound",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !Store.state.auth.status.loggedIn) {
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next("/NotFound");
  } else {
    next();
  }
});

export default router;
